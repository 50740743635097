.sp-demarche-step-recap {
    background-color: #fff;
    border: 1px solid var(--blue-france-sun-113-625);
    border-radius: 4px;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
}

.sp-demarche-step-recap .sp-demarche-step-recap-icone {
    margin: .5em 0 0;
    color: var(--blue-france-sun-113-625);
}