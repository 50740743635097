.logged-in {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.logged-in h2 {
  transform: scale(3);
}

.logged-in pre {
  background-color: var(--bs300);
  color: var(--g700);
  padding: 1em;
  border-radius: 2px;
  border: 1px solid transparent;
}

.logged-in pre.verified {
  border-left: 2px solid var(--success);
  position: relative;
}
.logged-in pre.verified::before {
  content: '✔️';
  color: var(--success);
  font-size: 2rem;
  position: absolute;
  top: 0;
  left: -2rem;
}
