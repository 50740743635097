.sp-input-group {
	position: relative;
	margin-top: .5rem;

	.btn-in-input {
		padding: 0 .2rem;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: initial;
		border: 0;
	}
}