.sp-demarche-step-titre {
    font-weight: 400;
    font-size: 1.125rem;
    text-align: center;
    margin: 3rem 0 1rem;
}

.sp-demarche-step-titre .sp-demarche-step-titre-nombre {
	display: inline-block;
	width: 3rem;
	height: 3rem;
	line-height: 2;
	font-size: 1.375rem;
	font-weight: 700;
	text-align: center;
	color: #fff;
	background-color: var(--blue-france-sun-113-625);
	border-radius: 50%;
}

.sp-demarche-step-titre .sp-demarche-step-titre-label {
    color: var(--blue-france-sun-113-625);
    display: block;
    padding-top: .5rem;
    font-size: 1.5rem;
}