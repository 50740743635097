.dile-accueil-titre {
	font-weight: normal;
	font-size: 1.125rem;
	margin: 3rem 0 1rem;
}

.dile-accueil-titre-label {
	color: var(--blue-france-sun-113-625);
	display: block;
	font-size: 1.5rem;
}

.dile-accueil-titre-icon-container {
	display: inline-block;
	width: 3rem;
	height: 3rem;
	line-height: 2.0;
	font-size: 1.5rem;
	font-weight: bold;
	color: white;
	background-color: var(--blue-france-sun-113-625);
	border-radius: 50%;
}

.ri-arrow-left-right-line-white {
	display: inline-block;
	width: 1em;
	height: 1em;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
	top: .3rem;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M16.05 12.05L21 17l-4.95 4.95l-1.414-1.415L17.172 18H4v-2h13.172l-2.536-2.535zm-8.1-10l1.414 1.414l-2.536 2.535H20v2H6.828l2.536 2.536L7.95 11.95L3 7z'/%3E%3C/svg%3E");
}

.ri-arrow-left-right-line-black {
	display: inline-block;
	width: 1em;
	height: 1em;
	font-size: 1.5em;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
	top: .3rem;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%2383838' d='M16.05 12.05L21 17l-4.95 4.95l-1.414-1.415L17.172 18H4v-2h13.172l-2.536-2.535zm-8.1-10l1.414 1.414l-2.536 2.535H20v2H6.828l2.536 2.536L7.95 11.95L3 7z'/%3E%3C/svg%3E");
}

.sp-accent {
	color: var(--blue-france-sun-113-625);
}

.bloc-icon {
	display: block;
	position: static;
	line-height: 1;
	color: inherit;
}

.sp-btn-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
}