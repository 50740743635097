.url-deconnexion {
	margin-top: 30px;

	a[href] {
		background-image: none;
	}
}

.dile-titre-demarche {
	color: var(--blue-france-sun-113-625);
	font-size: 1.375rem;
}

.footer-title {
	position: absolute;
	overflow: hidden;
	width: 0px;
	height: 0px;
}