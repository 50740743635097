.piece-justificative-texte-aide {
	padding: 1rem 0;
}

.sp-justif {
	background-color: #bfbfe3;
    padding: 1rem 0 .5rem;

	.sp-justif-title {
	    color: #000074;
	    padding: 0 1rem;
	    margin-bottom: 1rem;
	
		p {
		    margin: 0;
		}

		h3 {
		    color: var(--blue-france-sun-113-625);
		    margin: 0 0 .5rem;
		}

		.sp-btn-info:hover{
			background-color: unset !important;
		}
	}

	.sp-justif-content {
	    padding: .5rem 1rem 0;

		p:not(.fr-error-text), .piece-jointe-title, label, .fr-hint-text {
		    color: var(--blue-france-sun-113-625);
		}
	}

	.fr-form-group:last-child {
		margin-bottom: .5rem;
	}

	.fr-form-group div {
		position: relative;
	}
}

.sp-btn-info-target {
	background-color: #fff;
	padding: .5rem 1rem;

	h4 {
		color: var(--blue-france-sun-113-625);
	}
}

.piece-jointe-title {
	margin-bottom: 1rem;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 700;
}

.sp-btn-upload {
    background-color: #fff;
    display: block;
    box-shadow: none;
    line-height: 1.5;
    padding: .5rem;
    text-align: center;

	label {
		cursor: pointer;

		span {
			display: block;
		}
	}

	p {
		margin: 0;
	}

	.sp-input-group {
		display: flex;
		justify-content: space-between;
		.sp-file-name {
			font-size: 0.875rem;
		}
	}
}

.sp-btn-upload.sp-btn-upload--is-upload {
    padding: .25rem .5rem;
}