.fr-input-group.champ-referentiel .fr-input {
  margin-top: .5rem;
}

.fr-input-group.champ-referentiel .icon-search {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 2.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--focus-z-index);
}

.fr-input-group.champ-referentiel .icon-search::before {
  font-size: 1.5rem;
}

.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__suggestions-container {
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

.fr-input-group.champ-referentiel .react-autosuggest__suggestions-list {
  background: white;
	top: 100%;
  left: 0;
  width: 100%;
  list-style-type: none;
  padding-left: 0;
  margin-top: 4px;

  box-shadow: 1px 3px 5px #666;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}

.fr-input-group.champ-referentiel .react-autosuggest__suggestion {
  font-size: 0.875rem;
  margin: 0;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
}

.fr-input-group.champ-referentiel .react-autosuggest__suggestion::before {
  content: "";
}

.fr-input-group.champ-referentiel .react-autosuggest__suggestion--highlighted {
  background: #abb8df;
}

.fr-input-group.champ-referentiel input[type="search"]::-webkit-search-decoration,
.fr-input-group.champ-referentiel input[type="search"]::-webkit-search-cancel-button,
.fr-input-group.champ-referentiel input[type="search"]::-webkit-search-results-button,
.fr-input-group.champ-referentiel input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
