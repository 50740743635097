.sp-demarche-step-recap hr {
    color: var(--blue-france-sun-113-625);
    border-color: var(--blue-france-sun-113-625);
	border: inherit;
	padding: 0;
}

.sp-demarche-step-recap .sp-demarche-step-recap-modifier {
    text-align: right;
    margin: -.75rem -.5rem 0 0;
}

.bouton-envoi-disabled {
	background-color: var(--blue-france-sun-113-625) !important;
}

.dot-flashing {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4px;

	span {
		width: 8px;
		height: 8px;
		background-color: white;
		border-radius: 50%;
		opacity: 0.3;

		animation: flashing-dot 0.4s infinite alternate;

		&:nth-child(1) {
			animation-delay: 0s;
		}

		&:nth-child(2) {
			animation-delay: 0.2s;
		}

		&:nth-child(3) {
			animation-delay: 0.4s;
		}
	}
}

@keyframes flashing-dot {

	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.6;
	}
	100% {
		opacity: .3;
	}
}