.dile-text--center {
	text-align: center;
}

.dile-text--right {
	text-align: right;
}

.dile-display--none {
	display: none;
}

.fr-form-group {
	margin-bottom: 2rem;
}